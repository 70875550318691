<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            class="align-text-top"
            icon="HomeIcon"
            size="15"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t('label_system_gmail_address') }}
        </b-breadcrumb-item>
      </div>
    </div>

    <b-card no-body>

      <b-card-header class="pb-50 mt-2 mx-2">
        <h5>{{ $t('label_system_gmail_address') }}</h5>
      </b-card-header>

      <b-card-body>

        <b-alert
          class="mx-2"
          variant="info"
          show
        >
          <div class="aler-body">
            {{ $t('label_general_gmail_set_info') }}
          </div>
        </b-alert>

        <validation-observer
          #default="{ handleSubmit, invalid }"
          :ref="PREFIX + '_FORM'"
          tag="form"
          class="px-2"
        >
          <div class="divider divider-left pb-1">
            <div class="divider-text font-medium-1 text-primary">SMTP</div>
          </div>
          <validation-provider
            #default="validationProps"
            :name="$t('label_system_gmail_address')"
            rules="required|email"
          >
            <b-form-group
              :label="$t('label_system_gmail_address')"
              class=""
            >
              <b-form-input
                v-model="smtp_user"
                :placeholder="$t('label_system_gmail_address')"
                :state="getValidationState(validationProps)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider

            #default="validationProps"
            :name="$t('label_gmail_password')"
            rules="required"
            slim
          >
            <b-form-group
              :label="$t('label_gmail_password')"
            >
              <b-form-input
                v-model="smtp_pass"
                type="password"
                :placeholder="$t('label_gmail_password')"
                :state="getValidationState(validationProps)"
                trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--<span class="mb-1 d-flex align-items-center"><feather-icon class="text-primary mr-50 " icon="InfoIcon"></feather-icon> {{$t('label_workingtime_round_desc')}}</span>-->

          <validation-provider
            #default="validationProps"
            :name="$t('label_smtp_host')"
            rules="required"
          >
            <b-form-group
              :label="$t('label_smtp_host')"
              class=""
            >
              <b-form-input
                v-model="smtp_host"
                :placeholder="$t('label_smtp_host')"
                :state="getValidationState(validationProps)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationProps"
            :name="$t('label_smtp_port')"
            rules="required"
          >
            <b-form-group
              :label="$t('label_smtp_port')"
              class=""
            >
              <b-form-input
                v-model="smtp_port"
                :placeholder="$t('label_smtp_port')"
                :state="getValidationState(validationProps)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="divider divider-left py-1">
            <div class="divider-text font-medium-1 text-primary">IMAP</div>
          </div>

          <validation-provider
              #default="validationProps"
              :name="$t('label_system_gmail_address')"
              rules="required|email"
          >
            <b-form-group
                :label="$t('label_system_gmail_address')"
                class=""
            >
              <b-form-input
                  v-model="imap_user"
                  :placeholder="$t('label_system_gmail_address')"
                  :state="getValidationState(validationProps)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider

              #default="validationProps"
              :name="$t('label_gmail_password')"
              rules="required"
              slim
          >
            <b-form-group
                :label="$t('label_gmail_password')"
            >
              <b-form-input
                  v-model="imap_pass"
                  type="password"
                  :placeholder="$t('label_gmail_password')"
                  :state="getValidationState(validationProps)"
                  trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationProps)">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--<span class="mb-1 d-flex align-items-center"><feather-icon class="text-primary mr-50 " icon="InfoIcon"></feather-icon> {{$t('label_workingtime_round_desc')}}</span>-->

          <validation-provider
              #default="validationProps"
              :name="$t('label_smtp_host')"
              rules="required"
          >
            <b-form-group
                :label="$t('label_smtp_host')"
                class=""
            >
              <b-form-input
                  v-model="imap_host"
                  :placeholder="$t('label_smtp_host')"
                  :state="getValidationState(validationProps)"
                  trim
              />
              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
              #default="validationProps"
              :name="$t('label_smtp_port')"
              rules="required"
          >
            <b-form-group
                :label="$t('label_smtp_port')"
                class=""
            >
              <b-form-input
                  v-model="imap_port"
                  :placeholder="$t('label_smtp_port')"
                  :state="getValidationState(validationProps)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
              #default="validationProps"
              :name="$t('label_smtp_port')"
              rules="required"
          >
            <b-form-group
                :label="$t('label_imap_sent_folder')"
                class=""
            >
              <b-form-input
                  v-model="imap_sent_folder"
                  :placeholder="$t('label_imap_sent_folder')"
                  :state="getValidationState(validationProps)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div class="d-flex mt-3 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
            >
              {{ $t('label_save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click.prevent="$router.go(-1)"
            >{{ $t('label_cancel') }}
            </b-button>
          </div>
        </validation-observer>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>

    import {
        BCard, BCardHeader, BCardBody,
        BAlert, BButton, BFormGroup,
        BFormInput, BFormInvalidFeedback,
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    export default {
        components: {
            BCard,
            BCardHeader,
            BCardBody,
            BAlert,
            BButton,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            ValidationProvider,
            ValidationObserver,
        },
        props: ['selectOption'],
        data() {
            return {
                PREFIX: 'general_setting',
                smtp_pass:'',
                smtp_user: '',
                smtp_host: '',
                smtp_port: '',
                imap_pass: '',
                imap_user: '',
                imap_host: '',
                imap_port: '',
                imap_sent_folder: '',
            }
        },
        created() {
            this.getPageData();
        },
        methods: {
            getPageData() {
                this.async('get', '/general_settings', {params:{}}, function(resp){
                    this.smtp_pass = resp.data.smtp_pass;
                    this.smtp_user = resp.data.smtp_user;
                    this.smtp_host = resp.data.smtp_host;
                    this.smtp_port = resp.data.smtp_port;

                    this.imap_pass = resp.data.imap_pass;
                    this.imap_user = resp.data.imap_user;
                    this.imap_host = resp.data.imap_host;
                    this.imap_port = resp.data.imap_port;
                    this.imap_sent_folder = resp.data.imap_sent_folder;
                });
            },

            onSubmit() {
              const smtp_payload = {
                smtp_pass: this.smtp_pass,
                smtp_user: this.smtp_user,
                smtp_host: this.smtp_host,
                smtp_port: this.smtp_port,
              }

              const imap_payload = {
                imap_pass: this.imap_pass,
                imap_user: this.imap_user,
                imap_host: this.imap_host,
                imap_port: this.imap_port,
                imap_sent_folder: this.imap_sent_folder,
              }
                this.async('post', '/general_settings/smtp', smtp_payload, function (resp) {
                }, null, null, true);
              this.async('post', '/general_settings/imap', imap_payload, function (resp) {
              });
            }
        }
    }
</script>

